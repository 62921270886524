<template>
  <div v-if="portfolio_page" class="portfolioPage">
    <v-row
      class="portfolioPage"
      style="margin-right: 0!important; margin-left: 0!important; margin-top: 12px"
    >
      <v-col cols="12" class="pt-0">
        <v-row class="adaptiveNameCategoryPortfolio">
          <v-col cols="10" offset="1">
            <v-row>
              <v-col
                cols="12"
                class="categoryPortfolioPage pa-0 pt-11"
                style="text-align: left; padding-left: 10% !important"
              >
                <p>
                  <span>Категории: </span>Все категории,
                  {{ portfolio_page.category }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="titlePortfolioPage pa-0 pt-2"
                style="text-align: left; padding-left: 10% !important"
              >
                <span>{{ portfolio_page.name }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="colAdaptive">
                <div class="adaptive" style="position: relative">
                  <img src="../../public/img/adaptiv.png" alt="" />
                  <div class="macbook">
                    <img src="../../public/img/macbook.png" alt="" />
                  </div>
                  <div class="adaptiveSM">
                    <img
                      v-if="!portfolio_page.image_sm"
                      src="../../public/img/icons/placeholder.png"
                      alt=""
                    />
                    <img
                      v-else
                      :src="url_img(portfolio_page.image_sm)"
                      alt=""
                    />
                  </div>
                  <div class="adaptiveXL">
                    <img
                      v-if="!portfolio_page.image_xl"
                      src="../../public/img/icons/placeholder.png"
                      alt=""
                    />
                    <img else :src="url_img(portfolio_page.image_xl)" alt="" />
                  </div>
                  <div class="adaptiveMD">
                    <img
                      v-if="!portfolio_page.image_md"
                      src="../../public/img/icons/placeholder.png"
                      alt=""
                    />
                    <img else :src="url_img(portfolio_page.image_md)" alt="" />
                  </div>
                  <div class="adaptiveLG">
                    <img
                      v-if="!portfolio_page.image_lg"
                      src="../../public/img/icons/placeholder.png"
                      alt=""
                    />
                    <img else :src="url_img(portfolio_page.image_lg)" alt="" />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="10" class="contentTextPortfolioPage mx-auto">
            <v-row>
              <v-col cols="9" class="taskPortfolioPage pa-0 pl-3 mx-auto">
                <p v-if="portfolio_page.task">
                  <span>Задача: </span>{{ portfolio_page.task }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9" class="worksPortfolioPage pa-0 pl-3 mx-auto">
                <p><span>Работы: </span>{{ portfolio_page.work }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9" class="mx-auto">
                <v-dialog width="500" v-model="showRequestForm1">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="btn_feedback_portfolio_black"
                      elevation="2"
                      outlined
                      rounded
                      size="x-large"
                      v-bind="props"
                      ><span style="width:30px"></span><span class="span_btn_feedback">Оставить заявку</span>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 taskFormCardHeader">
                      Отправить заявку
                      <div class="flex-grow-1"></div>
                      <v-btn
                          class="taskFormClose"
                          color="primary"
                          variant="text"
                          rounded
                          style="width: 50px; height: 50px; min-width: 0"
                          @click="showRequestForm1 = false"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text class="taskFormCardText">
                      <TaskFormCard></TaskFormCard>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="background-color:rgba(109, 180, 201, 0.3);">
          <v-col cols="12" lg="9" class="mx-auto my-auto">
            <PortfolioSlider />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="screenIphone">
            <v-row>
              <v-col cols="12" class="blockIphone">
                <v-row>
                  <v-col class="contentBlockIphone" cols="12" lg="6">
                    <h1 class="titleBlockIphone">Мобильная версия</h1>
                    <p class="contentTextBlockIphone">
                      Адаптивная верстка для просмотра сайта на различных
                      мобильных устройствах
                    </p>
                    <v-dialog width="500" v-model="showRequestForm2">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          class="btn_feedback_lg"
                          elevation="2"
                          outlined
                          rounded
                          size="x-large"
                          v-bind="props"
                          ><span style="width:30px"></span><span class="span_btn_feedback_lg"
                            >Оставить заявку</span
                          >
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 taskFormCardHeader">
                          Отправить заявку
                          <div class="flex-grow-1"></div>
                          <v-btn
                              class="taskFormClose"
                              color="primary"
                              variant="text"
                              rounded
                              style="width: 50px; height: 50px; min-width: 0"
                              @click="showRequestForm2 = false"
                          >
                            <v-icon>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-card-title>

                        <v-card-text class="taskFormCardText">
                          <TaskFormCard></TaskFormCard>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <IphoneSlider />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="iphoneFeedBack">
                <v-dialog width="500" v-model="showRequestForm3">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="btn_feedback_sm"
                      elevation="2"
                      outlined
                      rounded
                      size="x-large"
                      v-bind="props"
                      ><span style="width:30px"></span><span class="span_btn_feedback_sm">Оставить заявку</span>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 taskFormCardHeader">
                      Отправить заявку
                      <div class="flex-grow-1"></div>
                      <v-btn
                          class="taskFormClose"
                          color="primary"
                          variant="text"
                          rounded
                          style="width: 50px; height: 50px; min-width: 0"
                          @click="showRequestForm3 = false"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text class="taskFormCardText">
                      <TaskFormCard></TaskFormCard>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PortfolioSlider from "@/components/ui/PortfolioSlider";
import IphoneSlider from "@/components/ui/IphoneSlider";
import TaskFormCard from "@/components/ui/TaskFormCard";
export default {
  name: "PortfolioPage",
  props: ["title"],
  components: { PortfolioSlider, IphoneSlider, TaskFormCard },
  data() {
    this.$store.dispatch("portfolio/GET_PORTFOLIO");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_CATEGORIES");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_IMAGE");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_WORK");
    this.$store.dispatch("portfolio/GET_PORTFOLIO_WORK_CON");
    return {
      showRequestForm1: false,
      showRequestForm2: false,
      showRequestForm3: false,
      see: 1,
      closeOnContentClick: false
    };
  },
  computed: {
    portfolio_page() {
      return this.$store.getters["portfolio/portfolio_page"](
        this.$route.params.title
      );
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Портфолио";
      }
    }
  },
  methods: {
    url_img(get) {
      "use strict";
      var start_url = window.location.href.split("/")[2];
      if (get === undefined) {
        return (
          "http://" +
          start_url +
          "/api/load_portfolio_image?url=media/placeholder.png"
        );
        // return "http://127.0.0.1:5001/api/load_portfolio_image?url=media/placeholder.png";
      } else
        return (
          "http://" +
          start_url +
          "/api/load_portfolio_image?url=" +
          get.url_image
        );
      // return (
      //   "http://127.0.0.1:5001/api/load_portfolio_image?url=" + get.url_image
      // );
    }
  }
};
</script>

<style scoped></style>
