<script>
export default {
  name: "OpenDialogButton",
  methods: {
    openDialog() {
      this.$store.dispatch("feedbackDialogStore/openDialog");
    },
  },
};
</script>

<template>
  <v-btn
    class="btn_feedback btn_feedback_component"
    elevation="2"
    outlined
    rounded
    variant="text"
    size="x-large"
    @click="openDialog"
    ><span style="width:30px"></span><span class="span_btn_feedback">Оставить заявку</span>
  </v-btn>
  <v-btn
    size="x-large"
    variant="text"
    icon
    @click="openDialog"
    class="mobileMenuSticky btn_feedback_mobile"
  >
    <v-icon size="x-large" color="#BACF34">mdi-email-edit-outline</v-icon>
  </v-btn>
</template>

<style scoped></style>
