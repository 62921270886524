<template>
  <swiper
    :options="swiperOption"
    :modules="modules"
    navigation
    :pagination="{ clickable: true }"
    class="no-indentation mainSwiper"
  >
    <swiper-slide>
      <v-container fluid class="swiperSlide">
          <v-row>
            <v-col
              cols="12"
            >
              <div class="parallax-bg"></div>
              <div data-swiper-parallax="-400" class="swiperBackground"></div>
              <div class="mx-auto swiperInfo">
                <div data-swiper-parallax="-100" class="swiperHeader">
                  Разработка сложных систем автоматизации бизнес-процессов
                </div>
                <div data-swiper-parallax="-200" class="swiperText">
                  <p>
                    B2B приложение highload-класса, интегрированные с
                    внутренниими учетными системами, внешними сервисами и
                    оборудованием
                  </p>
                </div>
                <div
                  data-swiper-parallax="-300"
                  class="swiperBtn"
                  style="margin-left: 0!important;"
                >
                  <v-btn class="btnReadMore" rounded depressed @click="pushAboutCompany()"
                    >Читать дальше</v-btn
                  >
                  <v-dialog width="500" v-model="showRequestForm1">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        class="btn_feedback"
                        elevation="2"
                        outlined
                        rounded
                        text
                        x-large
                        v-bind="props"
                        ><span style="width:30px"></span><span class="span_btn_feedback">Оставить заявку</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5 taskFormCardHeader">
                        Отправить заявку
                        <div class="flex-grow-1"></div>
                        <v-btn
                            class="taskFormClose"
                            color="primary"
                            variant="text"
                            rounded
                            style="width: 50px; height: 50px; min-width: 0"
                            @click="showRequestForm1 = false"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text class="taskFormCardText">
                        <TaskFormCard></TaskFormCard>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </swiper-slide>
  </swiper>
</template>
<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Navigation, Pagination } from "swiper/modules";
  import 'swiper/swiper-bundle.css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';

  // Import Swiper styles
  import 'swiper/css';
  import TaskFormCard from "@/components/ui/TaskFormCard.vue";

  export default {
    components: {
      Swiper,
      SwiperSlide,
      TaskFormCard
    },
    data() {
      return {
        showRequestForm1: false
      }
    },
    methods: {
    pushNews() {
      this.$router.push("news_page/1").catch(() => {});
    },
    pushAboutCompany() {
      this.$router.push("about_company").catch(() => {});
    },
    pushTypesOfActivities() {
      this.$router.push("/types_of_activities").catch(() => {});
    },
    pushPartnership() {
      this.$router.push("/for_suppliers").catch(() => {});
    }
  },
    setup() {
      return {
        swiperOption: {
          speed: 1200,
          loop: false,
          autoplay: {
            delay: 700000,
            disableOnInteraction: false
          },
          parallax: true,
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true
          // },
          // navigation: {
          //   nextEl: "portfolio-slider-next-btn",
          //   prevEl: "portfolio-slider-prev-btn"
          // }
        },
        modules: [Navigation, Pagination]
      };
    },
  };
</script>
<style lang="scss" scoped>
.swiper-slide {
  font-size: 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
  background-color: transparent !important;
  justify-content: space-around !important;
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-color: #1c394e;
  opacity: 0.2;
  z-index: -1;
}
</style>
